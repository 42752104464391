const searchPathOptions = [
  { routes: "/", label: "Home Page" },
  // { routes: "/find-creators", label: "Find Creators" },
  { routes: "/pricing", label: "Pricing" },
  { routes: "/resources", label: "Resources" },
  { routes: "/signup", label: "Register" },
  { routes: "/adult-signup", label: "Adult Signup" },
  { routes: "/about-us", label: "About Us" },
  { routes: "/community-guidelines", label: "Community Guidelines" },
  { routes: "/terms-conditions", label: "Terms & Conditions" },
  { routes: "/privacy-policy", label: "Privacy Policy" },
  { routes: "/post-job", label: "Post Job" },
  { routes: "/how-it-works", label: "How It Works" },
  { routes: "/login", label: "Login" },
  { routes: "/talent-dashboard", label: "Talent Dashboard" },
  { routes: "/brand-signup", label: "Brand Signup" },
  { routes: "/contact-us", label: "Contact Us" },
  { routes: "/list-jobs", label: "List Jobs" },
  { routes: "/applied-jobs", label: "Applied Jobs" },
  { routes: "/saved-jobs", label: "Saved Jobs" },
  { routes: "/create-jobs", label: "Create Jobs" },
  { routes: "/find-talents", label: "Find Talents" },
  { routes: "/favorite-talents", label: "Favorite Talents" },
  { routes: "/applicants", label: "Applicants" },
  { routes: "/edit-talent-profile", label: "Edit Talent Profile" },
  { routes: "/edit-brand-profile", label: "Edit Brand Profile" },
  { routes: "/talent-notification", label: "Talent Notification" },
  { routes: "/talent-settings", label: "Talent Settings" },
  { routes: "/brand-settings", label: "Brand Settings" },
  { routes: "/talent-home", label: "Talent Home" },
  { routes: "/brand-notification", label: "Brand Notification" },
  { routes: "/get-booked", label: "Get Booked" },
  { routes: "/careers", label: "Careers" },
  { routes: "/become-affliate", label: "Become Affiliate" },
  { routes: "/investors", label: "Investors" },
  { routes: "/feedback", label: "Feedback Reporting" },
];

export default searchPathOptions;
